const testIds = {
  btn: "btn",
  wrapper: "buttons-wrapper",
  confirmationWrapper: "confirmation-wrapper",
  btnConfirm: "btn-confirm",
  btnCancel: "btn-cancel",
  iconConfirm: "icon-confirm",
  iconCancel: "icon-cancel",
};

export default testIds;
