import React from "react";

import Main from "../Main/Main";

import "./App.css";

const App = (): JSX.Element => (
  <div data-testid="app" className="App">
    <Main />
  </div>
);

export default App;
